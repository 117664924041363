@import 'main.scss';
.heroContainer {
  position: relative;
  height: 125vh;
  width: 100%;
  cursor: none;
  display: flex;
  align-items: flex-start;

  .fade {
    position: absolute;
    pointer-events: none;

    bottom: 0;

    width: 100vw;
    height: 300px;

    background: linear-gradient(
      to top,
      rgba(251, 245, 255, 1),
      rgba(251, 245, 255, 0)
    );

    z-index: 21;
  }

  .canvas {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 125vh;
  }

  .titleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    margin-top: 107px;

    @media screen and (min-width: 401px) {
      max-width: 343px;
      margin-top: 140px;
    }

    @media screen and (min-width: 1001px) {
      max-width: 686px;
      margin-left: 0;
    }

    @media screen and (min-width: 1201px) {
      max-width: 823px;
      margin-left: clamp(0px, calc((149 * ((100vw - 1201px) / 399))), 173px);
    }

    @media screen and (min-width: 1601px) {
      max-width: 994px;
      margin-left: clamp(0px, calc((115 * ((100vw - 1601px) / 299))), 139px);
    }

    @media screen and (min-width: 1901px) {
      max-width: 994px;
      margin-left: 139px;
    }

    h1 {
      color: var(--primary);
      text-align: right;

      .firstText {
        flex-direction: column;

        @media screen and (min-width: 400px) {
          flex-direction: row;
        }
      }
    }
  }
}
