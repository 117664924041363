@import 'main.scss';
.sectionContainer {
  width: 100%;
  padding-top: 200px;

  .casesContainer {
    font-family: var(--font-sora);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .caseContainer > * {
      pointer-events: none;
    }

    @media screen and (min-width: 1000px) {
      overflow: initial;
      flex-direction: row;
      justify-content: space-between;
      gap: 24px;
    }

    .ourProjects {
      display: flex;
      flex-direction: column;
      height: fit-content;
      gap: 24px;
      width: fit-content;
      position: relative;
      z-index: 10;

      @media screen and (min-width: 1000px) {
        gap: 40px;
        padding-bottom: 132px;
        position: sticky;
        top: 200px;
      }

      h2 {
        color: var(--primary);
        display: flex;
        flex-direction: column;
      }

      .description {
        color: var(--primary, #17082f);
        font-family: var(--font-sora);
        font-size: 18px;
        font-weight: 300;
        line-height: 26px;
        max-width: 500px;

        @media screen and (min-width: 1200px) {
          font-size: 20px;
          font-weight: 300;
          line-height: 26px;
        }
      }
    }
  }
}

.cases {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  margin-top: 60px;
  padding-bottom: 24px;

  @media screen and (min-width: 1000px) {
    max-width: 376px;
    margin-top: 0px;
    gap: 170px;
    margin-top: 170px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 482px;
    gap: 170px;
    margin-top: 170px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 514px;
    gap: 170px;
    margin-top: 170px;
  }

  @media screen and (min-width: 1600px) {
    max-width: 621px;
    gap: 170px;
    margin-top: 170px;
  }
}

.case {
  width: 100%;
  height: 421px;

  @media screen and (min-width: 400px) {
    max-width: 350px;
    height: 469px;
  }

  @media screen and (min-width: 1000px) {
    max-width: 376px;
    height: 443px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 482px;
    height: 567px;
  }

  @media screen and (min-width: 1440px) {
    max-width: 514px;
    height: 605px;
  }

  @media screen and (min-width: 1600px) {
    max-width: 621px;
    height: 732px;
  }

  .caseContainer {
    position: relative;
    width: 100%;
    height: 421px;

    @media screen and (min-width: 400px) {
      height: 469px;
    }

    @media screen and (min-width: 1000px) {
      height: 443px;
    }

    @media screen and (min-width: 1200px) {
      height: 567px;
    }

    @media screen and (min-width: 1440px) {
      height: 605px;
    }

    @media screen and (min-width: 1600px) {
      height: 732px;
    }

    & * {
      transition: all 0.35s ease-in-out;
    }

    svg {
      position: absolute;
      z-index: 5;
      width: 295px;
      height: 349px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (min-width: 400px) {
        width: 350px;
        height: 413px;
      }

      @media screen and (min-width: 1000px) {
        width: 100%;
        height: 100%;
        bottom: unset;
        left: unset;
        transform: none;
      }
    }

    .caseContent {
      position: absolute;
      top: 0;
      z-index: 10;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media screen and (min-width: 400px) {
        max-width: 350px;
      }

      @media screen and (min-width: 1000px) {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        max-width: 324px;
        gap: 30px;
      }

      @media screen and (min-width: 1200px) {
        max-width: 416px;
      }

      @media screen and (min-width: 1600px) {
        max-width: 466px;
      }

      picture {
        width: 270px;
        height: 224px;

        @media screen and (min-width: 400px) {
          width: 350px;
          height: 290px;
        }

        @media screen and (min-width: 1000px) {
          width: 100%;
          height: 269px;
        }

        @media screen and (min-width: 1200px) {
          height: 345px;
        }

        @media screen and (min-width: 1600px) {
          height: 387px;
        }

        img {
          height: 100%;
          margin: 0 auto;
        }
      }

      .caseInfo {
        display: flex;
        flex-direction: column;
        width: 100%;

        span {
          font-family: var(--font-avant);
          font-size: 24px;
          line-height: 29px;
          font-weight: bold;
          color: var(--primary);

          @media screen and (min-width: 400px) {
            font-size: 32px;
            line-height: 40px;
          }

          @media screen and (min-width: 1000px) {
            font-size: 32px;
            line-height: 50px;
          }

          @media screen and (min-width: 1200px) {
            font-size: 45px;
          }
        }

        p {
          font-family: var(--font-sora);
          font-size: 16px;
          line-height: 22px;
          color: var(--primary);
          font-weight: 400;
          margin-top: 11px;

          @media screen and (min-width: 400px) {
            margin-top: 8px;
          }

          @media screen and (min-width: 1000px) {
            line-height: 20px;
            max-height: 1px;
            margin-top: 16px;
            overflow: hidden;
          }
        }

        ul {
          display: flex;
          list-style: none;
          gap: 8px;
          align-items: center;
          margin-top: 11px;
          flex-wrap: wrap;

          @media screen and (min-width: 400px) {
            margin-top: 8px;
          }

          @media screen and (min-width: 1000px) {
            margin-top: unset;
          }

          li {
            font-family: var(--font-sora);
            font-size: 15px;
            line-height: 16px;
            color: var(--primary);
            font-weight: 700;
            text-transform: uppercase;

            small {
              padding-left: 8px;
              color: var(--Primary-300, #4c2882);
              font-size: 20px;
              font-weight: 400;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}

.caseContainerHoverState {
  div > div > p {
    margin-bottom: 16px !important;
  }

  picture {
    scale: 1.15 !important;
  }
}
